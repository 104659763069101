<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-form ref="form" v-model="formValid">
          <base-card>
            <v-card-title>Configuración Tiempo Inactividad</v-card-title>
            <v-card-text>
              <v-row>
                <v-col cols="12" sm="3">
                  <v-text-field
                    ref="Time"
                    v-model="dataCredentials.client"
                    label="Tiempo Inactividad (Horas)"
                    prepend-inner-icon="mdi-clock-outline"
                    type="number"
                    clearable
                    required
                    :rules="emisorFormRules"
                    @change="validateInput"
                  />
                </v-col>
                <v-col cols="12" sm="4">
                  <v-btn
                    class="ma-2"
                    style="
                      background-color: rgb(204 33 40);
                      color: white !important;
                    "
                    @click="sendHours"
                    :disabled="!formValid"
                  >
                    Registrar tiempo
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </base-card>
        </v-form>
      </v-col>
    </v-row>

    <v-snackbar v-model="snackbar" timeout="3000" top color="success">
      {{ msgSuccessAlert }}
    </v-snackbar>
  </v-container>
</template>

<script>
export default {
  name: "RegisterCredentialTef",
  data() {
    return {
      dataCredentials: {
        client: this.getDefaultInactiveTime(),
      },
      snackbar: false,
      msgSuccessAlert: "Horas de Inactividad Actualizadas con Exito",
      formValid: false,
      emisorFormRules: [
        (v) => !!v || "Este campo es requerido",
        (v) => v >= 1 || "El mínimo es 1 hora",
        (v) => v <= 24 || "El máximo es 24 horas",
      ],
    };
  },
  methods: {
    getDefaultInactiveTime() {
      const storedTime = localStorage.getItem("TimeInactive");
      if (storedTime) {
        return storedTime;
      } else {
        const defaultTime = 1;
        localStorage.setItem("TimeInactive", defaultTime.toString());
        return defaultTime.toString();
      }
    },

    validateInput() {
      const inputValue = parseInt(this.dataCredentials.client, 10);
      if (isNaN(inputValue) || inputValue < 1 || inputValue > 24) {
        console.log("El tiempo de inactividad debe estar entre 1 y 24 horas.");
        this.dataCredentials.client = "1";
      }
    },

    sendHours() {
      const hours = parseInt(this.dataCredentials.client, 10);

      if (!isNaN(hours) && hours >= 1 && hours <= 24) {
        localStorage.setItem("TimeInactive", hours.toString());
        this.snackbar = true;
        console.log(
          "Tiempo de inactividad en horas guardado:",
          localStorage.getItem("TimeInactive")
        );
      } else {
        console.log("Por favor ingrese un valor válido para las horas.");
      }
    },
  },
};
</script>